import Main from './pages/main'
import Layouts from './components/Layouts';
import {isDesktop} from 'react-device-detect';
import Mobile from "./pages/mobile";
import React from "react";
import {Route, Router, Routes} from "react-router-dom";
import Filters from "./pages/filters";
import FiltersList from "./components/FiltersMobile/FiltersList";

const App: React.FC = () => {

    return (
        <Layouts>
            <Routes>
                <Route path="/" element={isDesktop ? <Main /> : <Mobile />} />
                <Route path="/filters" element={<Filters />} />
                <Route path="/filters-list" element={<FiltersList />} />
            </Routes>
        </Layouts>
    );
}

export default App;
