import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Flex, MenuProps, Space} from "antd";
import s from "./header.module.scss";
import moment from "moment/moment";
import {
    DownloadIcon, FiltersIcon,
    ImageIcon,
    LogoIcon,
    RentLogo,
    SaleBigLogo,
    SaleLogo,
    TerminalsBigLogo, TerminalsLogo
} from "../../assets/images/svg";
import Icon, {DownOutlined,FilterOutlined} from "@ant-design/icons";
import {getAllBids, getFiltersBits, setBullService, setRefDepartment, setTypeTable} from "../../store/bids";
import {useDispatch} from "react-redux";
import {SessionStorageManager} from "../TableComponent/sessionStorageManager";
import {useLocation, useNavigate} from "react-router-dom";

const HeaderMobile = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dropdownText, setDropdownText] = useState<string>('Аренда');
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: any = storageManager.getItem();
    const itemsDownload: MenuProps['items'] = [
        {key: '1', label: (<div onClick={() => handleDownload('excel')}>Скачать в Excel</div>)},
        {key: '2', label: (<div onClick={() => handleDownload('pdf')}>Скачать в PDF</div>)}
    ]
    const items: MenuProps['items'] = [
        {
            icon: <Icon component={RentLogo}/>,
            key: '1',
            label: 'Аренда',
            onClick: () => handleMenuClick('Аренда', 1),
        },
        {
            key: '2',
            label: 'Продажи',
            icon: <Icon component={SaleLogo}/>,
            onClick: () => handleMenuClick('Продажи', 2),
        },
        {
            key: '3',
            label: 'Терминалы',
            icon: <Icon component={TerminalsLogo}/>,
            onClick: () => handleMenuClick('Терминалы', 3),
        },
    ];

    useEffect(() => {
        if (storageLocal) {
            setDropdownText(storageLocal.labelFilter);
        } else {
            setDropdownText('Аренда');
        }
        if (!storageLocal) {
            storageManager.setItem({
                fiters: [{department: ['Аренда']}],
                pagination: {current: 1, page: 10, total: 0},
                bull: [],
                labelFilter: 'Аренда'
            });
        }
    }, []);

    const handleDownload = (type: string) => {

    }

    const handleMenuClick = async (label: string, type: number) => {
        setDropdownText(label);
        dispatch(setTypeTable(type));
        dispatch(setBullService([]));
        dispatch(setRefDepartment(true))
        sessionStorage.setItem('f' + location.pathname, label);
        if (storageLocal) {
            storageManager.updateItem({
                bull: [],
                arrFilters: null,
                pagination: {current: 1, page: 10, total: 0},
                fiters: [{department: [label]}],
                labelFilter: label
            });
        } else {
            await storageManager.setItem({
                pagination: {current: 1, page: 10, total: 0},
                bull: [],
                arrFilters: null,
                fiters: [{department: [label]}],
                labelFilter: label
            });
        }
        const getFiters: any = storageManager.getItem();
        if (getFiters) {
            filtersColumn(getFiters, 1, storageLocal?.pagination?.page || 10, 'DESC');
        }
    }

    const filtersColumn = (data: any, current: number, page: number, order: string) => {
        if (storageLocal && storageLocal.pagination !== undefined) {
            storageLocal.pagination.current = current;
            storageLocal.pagination.page = page;
        }
        if (Object.keys(data).length > 0) {
            dispatch(
                getFiltersBits({
                    data: data.fiters,
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        } else {
            dispatch(
                getAllBids({
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        }
    };

    const handleFilters = () => {
        navigate('/filters',{ state: { dropdownText } });
    }

    return (
        <Flex vertical style={{position: 'fixed', zIndex: 99}}>
            <Flex className={s.header__container} justify={"space-between"}>
                <Flex gap={16} align={"center"} justify={"space-between"} style={{width: '100%'}}>
                    <Icon component={LogoIcon} />
                    <Flex className={s.mobile_header__title}>
                        Актуальные ставки MyContainers на {moment().format('DD.MM.YYYY')}
                    </Flex>
                    <Flex>
                        <Dropdown menu={{items: itemsDownload}} placement="bottomRight">
                            <Button icon={<DownloadIcon width={'30px'} height={'30px'}/>}
                                    loading={loading} style={{width: 40, height: 40}}/>
                        </Dropdown>
                    </Flex>
                </Flex>
        </Flex>
            <Flex justify={"space-between"} className={s.header__container} style={{height: 50}}>
                <Flex gap={10} style={{width: 50}} onClick={handleFilters}>
                    <Icon component={FiltersIcon}/>
                    <div className={s.header__wrap}>Фильтры</div>
                </Flex>
                <Flex gap={10} className={s.header__wrap} style={{width: '50%'}}>
                    {dropdownText == 'Аренда' &&
                        <Icon component={ImageIcon}/>
                    }
                    {dropdownText == 'Продажи' &&
                        <Icon component={SaleBigLogo}/>
                    }
                    {dropdownText == 'Терминалы' &&
                        <Icon component={TerminalsBigLogo}/>
                    }
                    <Dropdown menu={{items, selectable: true, defaultSelectedKeys: ['1']}}
                              trigger={['click']} placement="bottomRight">
                        <Space style={{cursor: "pointer"}}>
                            <div className={s.header__wrap}>{dropdownText}</div>
                            <DownOutlined/>
                        </Space>
                    </Dropdown>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default HeaderMobile;