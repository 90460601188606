import React, { useEffect, useState } from 'react';
import { Button, Divider, Flex } from "antd";
import s from './filters.module.scss';
import Icon from "@ant-design/icons";
import { BackIcon } from "../../assets/images/svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {setCityData, setCityData1, setCountryData, setCountryData1} from "../../store/bids";
import {RootState} from "../../store";

interface FiltersMobileProps {
    dropdownText?: string;
}

const Index = ({ dropdownText }: FiltersMobileProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resourcesData: any  = useSelector((state: RootState) => state.bids.resourcesData);
    const countryFilter: any  = useSelector((state: RootState) => state.bids.countryFilter);
    const countryFilter1: any  = useSelector((state: RootState) => state.bids.countryFilter1);
    const cityFilter: any  = useSelector((state: RootState) => state.bids.cityFilter);
    const cityFilter1: any  = useSelector((state: RootState) => state.bids.cityFilter1);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [countryResponse, countryResponse1, cityResponse, cityResponse1] = await Promise.all([
                    axios.get(`https://test.api.mycontainers.ru/bot/bet_country_send?service=${dropdownText}`),
                    axios.get(`https://test.api.mycontainers.ru/bot/bet_country_target?service=${dropdownText}`),
                    axios.get(`https://test.api.mycontainers.ru/bot/bet_cities_send?service=${dropdownText}&country=${countryFilter}`),
                    axios.get(`https://test.api.mycontainers.ru/bot/bet_cities_target?service=${dropdownText}&country=${countryFilter1}`)
                ]);

                dispatch(setCountryData(countryResponse.data));
                dispatch(setCountryData1(countryResponse1.data));
                dispatch(setCityData(cityResponse.data));
                dispatch(setCityData1(cityResponse1.data));
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };
        fetchData();

    }, [dropdownText, countryFilter, countryFilter1, dispatch]);

    const handleBack = () => {
        navigate('/');
    };

    const handleFiltersList = (selectedVal: string) => {
        navigate('/filters-list', { state: { val: selectedVal, dropdownText } });
    };

    return (
        <>
            <Flex className={s.filters__header} align={"center"}>
                <Flex onClick={handleBack} align={"center"} style={{ padding: '0 10px' }}>
                    <Icon component={BackIcon} />
                    <div className={s.filters__header_back}>Назад</div>
                </Flex>
                <Flex align={"center"} style={{ position: 'relative', right: 44, width: '100%' }} justify={"center"}>
                    <div className={s.filters__title}>Фильтры</div>
                </Flex>
            </Flex>
            <Flex className={s.filters__container} vertical gap={12}>
                <Flex vertical gap={12}>
                    <div className={s.filters__input_label}>Тип контейнера</div>
                    <div className={s.filters__input}>Выбрать</div>
                </Flex>
                <Divider style={{ margin: 0 }} />
                <Flex vertical gap={12} onClick={() => handleFiltersList('country')}>
                    <div className={s.filters__input_label}>Локация выдачи</div>
                    <div className={s.filters__input}>
                        {countryFilter !== '' ? countryFilter : 'Выберите страну'}
                    </div>
                </Flex>
                <Flex vertical gap={12} onClick={() => handleFiltersList('city')}>
                    <div className={s.filters__input}>
                        {cityFilter !== '' ? cityFilter : 'Выберите город'}
                    </div>
                </Flex>
                {dropdownText === 'Аренда' && (
                    <>
                        <Divider style={{ margin: 0 }} />
                        <Flex vertical gap={12} onClick={() => handleFiltersList('country1')}>
                            <div className={s.filters__input_label}>Локация сдачи</div>
                            <div className={s.filters__input}>
                                {countryFilter1 !== '' ? countryFilter1 : 'Выберите страну'}
                            </div>
                        </Flex>
                        <Flex vertical gap={12} onClick={() => handleFiltersList('city1')}>
                            <div className={s.filters__input}>
                                {cityFilter1 !== '' ? cityFilter1 : 'Выберите город'}
                            </div>
                        </Flex>
                    </>
                )}
                <Button block size={'large'} style={{
                    marginTop: "auto",
                    background: '#5A00CE',
                    color: '#FFFFFF'
                }}>Показать варианты</Button>
            </Flex>
        </>
    );
};

export default Index;
