import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RestBids} from '../services/rest_bids';
import {IBet, IPaginationFetch, ITypes} from "../interface";


interface IBidsState {
    dataBids: Array<IBet>;
    dataAllBids: Array<IBet>;
    dataTypeContainers: Array<ITypes>
    dataTypeQuality: Array<ITypes>
    dataTypeDepartment: Array<ITypes>
    totalBids: 0;
    breadcrumb: [];
    dataFilters: [];
    hasFetchedResources: boolean;
    tableNum: number;
    bullService: [];
    countryData: [];
    countryData1: [];
    cityData: [];
    cityData1: [];
    resourcesData: [];
    countryFilter: string,
    countryFilter1: string,
    cityFilter: string;
    cityFilter1: string;
    refDepartment: boolean,
}

const initialState: IBidsState = {
    dataBids: [],
    dataAllBids: [],
    dataTypeContainers: [],
    dataTypeQuality: [],
    dataTypeDepartment: [],
    totalBids: 0,
    breadcrumb: [],
    dataFilters: [],
    hasFetchedResources: false,
    tableNum: 1,
    bullService: [],
    countryData: [],
    countryData1: [],
    resourcesData: [],
    cityData: [],
    cityData1: [],
    countryFilter: '',
    countryFilter1: '',
    cityFilter: '',
    cityFilter1: '',
    refDepartment: false,
}

export const getAllBids: any = createAsyncThunk(
    'getAllBids',
    async (payload: IPaginationFetch) => {
        return await RestBids.getAllBids(payload)
    }
)
export const getAllBidsExcel: any = createAsyncThunk(
    'getAllBidsExcel',
    async (payload: IPaginationFetch) => {
        return await RestBids.getAllBidsExcel(payload)
    }
)
export const filtersBits: any = createAsyncThunk(
    'filtersBits',
    async (payload: any) => {
        return await RestBids.filtersBits(payload)
    }
)
export const getFiltersBits: any = createAsyncThunk(
    'getFiltersBits',
    async (payload: {}) => {
        return await RestBids.getFiltersBits(payload)
    }
)
export const getResources: any = createAsyncThunk(
    'getResources',
    async () => {
        return await RestBids.getResources()
    }
)
const bidsSlice = createSlice({
    name: 'bids',
    initialState,
    reducers: {
        setFetchedResources(state, action) {
            state.hasFetchedResources = action.payload
        },
        clearFiltersFetch: function (state) {
            state.dataFilters = []
        },
        setTypeContainers(state, action) {
            state.dataTypeContainers = action.payload
        },
        setTypeQuality(state, action) {
            state.dataTypeQuality = action.payload
        },
        setTypeDepartment(state, action) {
            state.dataTypeDepartment = action.payload
        },
        setTypeTable(state, action) {
            state.tableNum = action.payload
        },
        setBullService(state, action) {
            state.bullService = action.payload
        },
        setCountryData(state, action) {
            state.countryData = action.payload
        },
        setCountryData1(state, action) {
            state.countryData1 = action.payload
        },
        setCityData(state, action) {
            state.cityData = action.payload
        },
        setCityData1(state, action) {
            state.cityData1 = action.payload
        },
        setResources(state, action) {
            state.resourcesData = action.payload
        },
        setCountryFilter(state, action) {
            state.countryFilter = action.payload
        },
        setCountryFilter1(state, action) {
            state.countryFilter1 = action.payload
        },
        setCityFilter(state, action) {
            state.cityFilter = action.payload
        },
        setCityFilter1(state, action) {
            state.cityFilter1 = action.payload
        },
        setRefDepartment(state, action) {
            state.refDepartment = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(filtersBits.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
        builder.addCase(getFiltersBits.fulfilled, (state, action) => {
            state.dataFilters = [];
            state.dataFilters = action.payload.rows
        })
    },
})
export default bidsSlice.reducer;
export const {
    setFetchedResources,
    clearFiltersFetch,
    setTypeContainers,
    setTypeQuality,
    setTypeDepartment,
    setTypeTable,
    setBullService,
    setCountryData,
    setCountryData1,
    setResources,
    setCityData,
    setCityData1,
    setCountryFilter,
    setCountryFilter1,
    setCityFilter,
    setCityFilter1,
    setRefDepartment
} = bidsSlice.actions
