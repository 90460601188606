import socket from '../socket';
import {IPaginationFetch} from "../interface";

export class RestBids {

  static getAllBids(payload: IPaginationFetch): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('get-all-bids', payload);
      } catch (e) {  reject(e)}
    });
  }

  static getAllBidsExcel(payload: IPaginationFetch): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('get-all-bids-excel-client', payload);
      } catch (e) {  reject(e)}
    });
  }

  static filtersBits(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('filters-bits', payload, (response: any) =>{
            resolve(response)
        })
      } catch (e) {  reject(e)}
    });
  }

  static getFiltersBits(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('get-filters-bits', payload);
      } catch (e) {  reject(e)}
    });
  }

  static getResources(): Promise<any>{
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit('get-resources', (response: any) => {
          resolve(response)
        })
      } catch (e) {  reject(e)}
    })
  }
}
