import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Router} from "react-router-dom";
import {ConfigProvider} from "antd";
import ru_RU from 'antd/es/locale/ru_RU';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store";

import './assets/scss/index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider locale={ru_RU}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
);
