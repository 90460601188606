import { StorageData } from "../../interface";

export class SessionStorageManager {
  private storageKey: string;

  constructor(storageKey: string) {
    this.storageKey = storageKey;
  }

  // Получение данных из localStorage
  getItem(): StorageData | null {
    const data: any = sessionStorage.getItem(this.storageKey);
    return JSON.parse(data);
  }

  // Сохранение данных в localStorage
  async setItem(data: StorageData) {
    sessionStorage.setItem(this.storageKey, JSON.stringify(data));
  }


  // Обновление данных
  updateItem(updatedBull: any): void {
    const storageData: StorageData = this.getItem() || {
      bull: [],
      fiters: {} };
    Object.assign(storageData,  updatedBull);
    this.setItem(storageData);
  }


  // Метод для удаления значения из sessionStorage
  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing item from sessionStorage:", error);
    }
  }

  // Метод для очистки sessionStorage
  clear(): void {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error("Error clearing sessionStorage:", error);
    }
  }
}