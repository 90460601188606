import React from 'react';
import {Avatar, Col, Divider, Flex, Row} from "antd";
import s from './card.module.scss'
import Icon from "@ant-design/icons";
import {
    arrowCityIcon,
    FlagBelarus,
    FlagChina,
    FlagKazakhstan,
    FlagRussia,
    FlagUzbekistan,
    FlagVietnam
} from "../../assets/images/svg";
import moment from "moment";

interface CardProps {
    dataAllBids?: any
}

const flagComponents: any = {
    'Китай': FlagChina,
    'Россия': FlagRussia,
    'Беларусь': FlagBelarus,
    'Казахстан': FlagKazakhstan,
    'Вьетнам': FlagVietnam,
    'Узбекистан': FlagUzbekistan,
};

const Card = ({dataAllBids}: CardProps) => {
   // console.log(dataAllBids)
    const renderCountryStart = (city: string, country: string) => {
        // Получаем компонент флага на основе страны
        const FlagComponent = flagComponents[country] || null;
        return (
            <Flex gap={6}>
                <Flex align="center">
                    {FlagComponent ? <Icon component={FlagComponent}/> : <Avatar/>}
                </Flex>
                <Flex vertical>
                    <div className={s.card__text_16}>{city}</div>
                    <div className={s.card__text_16}>{country}</div>
                </Flex>
            </Flex>
        );
    };

    const renderCountryEnd = (city: string, country: string) => {
        // Получаем компонент флага на основе страны
        const FlagComponent = flagComponents[country] || null;
        return (
            <Flex gap={6}>
                <Flex align="center">
                    {FlagComponent ? <Icon component={FlagComponent}/> : <Avatar/>}
                </Flex>
                <Flex vertical>
                    <div className={s.card__text_16}>{city}</div>
                    <div className={s.card__text_16}>{country}</div>
                </Flex>
            </Flex>
        );
    };

    return (
        <Flex className={s.card__mobile} vertical gap={10}>
            <Row align={'middle'}>
                <Col span={12}>
                    {dataAllBids.department === 'Терминалы' ?
                        <div className={s.card__mobile__price}>Терминал</div>
                        : <div className={s.card__mobile__price}>{dataAllBids.bet}</div>
                    }

                </Col>
                <Col span={12}>
                    <Flex gap={6} justify={"flex-end"}>
                        <Flex gap={6}>
                            <div className={s.card__mobile__date}>
                                {moment(dataAllBids.created_at).format('DD.MM.YYYY')}</div>
                            <div className={s.card__mobile__department}
                                 style={dataAllBids.department === 'Продажи' ?
                                     {color: '#00B288'} : dataAllBids.department === 'Терминалы' ?
                                         {color: '#F29100'} : {}}
                            >{dataAllBids.department}</div>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
            <Row align={'middle'} justify={"start"}>
                <Col span={dataAllBids.department === 'Аренда' ? 11 : 12}>
                    {renderCountryStart(dataAllBids.city1, dataAllBids.country1)}
                </Col>
                {dataAllBids.department === 'Аренда' &&
                    <Col span={2}> <Icon component={arrowCityIcon}/> </Col>
                }
                <Col span={dataAllBids.department === 'Аренда' ? 11 : 12}>
                    {dataAllBids.department === 'Аренда' ?
                        renderCountryEnd(dataAllBids.city2, dataAllBids.country2)
                        :
                        dataAllBids.department === 'Продажи' &&
                        <Row gutter={[12, 12]} justify={"end"}>
                            <div className={s.card__box_terminal}>{dataAllBids.quality}</div>
                            <div className={s.card__box_terminal}>{dataAllBids.type_ktk}</div>
                        </Row>
                    }
                </Col>
            </Row>
            {dataAllBids.department === 'Аренда' &&
                <>
                    <Divider style={{margin: 4}}/>
                    <Row align={'middle'} justify={"center"}>
                        <Col span={4} className={s.card__text_14_bold}>Тип</Col>
                        <Col span={8} className={s.card__text_14_bold}>Количество</Col>
                        <Col span={8} className={s.card__text_14_bold}>Своболные дни</Col>
                        <Col span={4} className={s.card__text_14_bold}>СНП</Col>
                    </Row>
                    <Row align={'middle'} justify={"center"}>
                        <Col span={4} className={s.card__text_14}>{dataAllBids.type_ktk}</Col>
                        <Col span={8} className={s.card__text_14}>{dataAllBids.col_ktk}</Col>
                        <Col span={8} className={s.card__text_14}>{dataAllBids.free_days}</Col>
                        <Col span={4} className={s.card__text_14}>{dataAllBids.cnp}</Col>
                    </Row>
                </>
            }
            {
                dataAllBids.department === 'Терминалы' &&
                <>
                    <Divider style={{margin: 4}}/>
                    <Row align={'middle'} justify={"center"}>
                        <Col span={8} className={s.card__text_14_bold}>Тип КТК</Col>
                        <Col span={8} className={s.card__text_14_bold}>Ставка 1 день</Col>
                        <Col span={8} className={s.card__text_14_bold}>Ставка 1 кран</Col>
                    </Row>
                    <Row align={'middle'} justify={"center"}>
                        <Col span={8} className={s.card__text_14}>{dataAllBids.type_ktk}</Col>
                        <Col span={8} className={s.card__text_14}>{dataAllBids.bet_1_day}</Col>
                        <Col span={8} className={s.card__text_14}>{dataAllBids.bet_1_crane}</Col>
                    </Row>
                </>
            }
        </Flex>
    );
};

export default Card;