import React from 'react';
import Card from "./Card";
import {Flex} from "antd";


interface CardListProps {
    dataAllBids?: any
}

const CardList = ({dataAllBids}: CardListProps) => {
    return (
        <Flex vertical gap={12}>
            {
                dataAllBids.map((bid: any, index: number) =>
                    <div key={index}>
                        <Card dataAllBids={bid}/>
                    </div>
                )
            }
        </Flex>
    );
};

export default CardList;