import React from 'react';
import {Flex, Select} from 'antd';
import {IColumnType} from './Table';
import {useLocation} from 'react-router-dom';
import {SessionStorageManager} from './sessionStorageManager';
import {StorageData} from '../../interface';

interface ColumnStringProps {
    columnFilters?: any,
    column: IColumnType<any>,
    searchFilter: (row: string, column: any) => void,
    handleChange: (option: string[], dates: [Date | null, Date | null],
                   dataInt: [number | '' | null | undefined, number | '' | null | undefined],
                   checked: boolean, columnIndex: number) => void,
    columnIndex: number,
    startDate: Date | null,
    endDate: Date | null,
    optionsFilter?: any,
    startIntRange?: number | null,
    endIntRange?: number | null
}

const ColumnString = ({
                          columnFilters,
                          column,
                          searchFilter,
                          handleChange,
                          columnIndex,
                          startDate,
                          endDate,
                          optionsFilter,
                          startIntRange,
                          endIntRange
                      }: ColumnStringProps) => {
    const location = useLocation();
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: StorageData | null = storageManager.getItem();

    return (
        <Flex vertical gap={7}>
            <Select
                allowClear
                mode="multiple"
                value={columnFilters || storageLocal?.arrFilters !== null && storageLocal?.arrFilters !== undefined ?
                    storageLocal?.arrFilters[columnIndex] : null}
                onSearch={(value) => searchFilter(value, column)}
                style={{width: 200}}
                placeholder={column.title}
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onChange={(option) => handleChange(option, [startDate, endDate],
                    [startIntRange, endIntRange], false, columnIndex)}
                options={Array.from(
                    new Set(
                        (optionsFilter || []).map((d: any) => {
                                if (d['bits_comment.text']) {
                                    return d['bits_comment.text']
                                } else {
                                    return d[column.dataIndex]
                                }
                            }
                        ).filter((val: any) => val !== undefined && val !== null && val !== ''),
                    ),
                ).map((uniqueValue) => ({
                    value: uniqueValue,
                    label: uniqueValue,
                }))}
            />
        </Flex>
    );
};

export default ColumnString;