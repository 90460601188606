import React, {useCallback, useEffect, useState} from 'react';
import s from "./page.module.scss";
import HeaderMobile from "../components/Header/HeaderMobile";
import CardList from "../components/Cards/CardList";
import {Flex, message, Skeleton} from "antd";
import socket from "../socket";
import {getAllBids, getFiltersBits, setRefDepartment} from "../store/bids";
import {SessionStorageManager} from "../components/TableComponent/sessionStorageManager";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {RootState} from "../store";

const Mobile = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [columnFilters, setColumnFilters] = useState<any>({});
    const [current, setCurrent] = useState<number>(1);
    const [page, setPage] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [dataAllBids, setDataAllBids] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const refDepartment = useSelector((state: RootState) => state.bids.refDepartment);
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: any = storageManager.getItem()

    useEffect(() => {
        if (storageLocal) {
            setCurrent(storageLocal.pagination.current);
            setColumnFilters(storageLocal.arrFilters);
            setCurrent(storageLocal.pagination.current);
            setPage(storageLocal.pagination.page);
            filtersColumn(storageLocal.fiters,
                storageLocal.pagination.current, storageLocal?.pagination?.page || 10, 'DESC')
        } else {
            filtersColumn([{department: ['Аренда']}], 1, 10, 'DESC')
        }
        socket.on('return-get-all-bids', resAllBids);
        socket.on('return-get-filters-bits', resFiltersBits);

        return () => {
            socket.off('return-get-all-bids', resAllBids);
            socket.off('return-get-filters-bits', resFiltersBits);
        };

    }, [refDepartment])

    const resAllBids = (response: any) => {
        if (response) {
            const data = response.order === "DESC" ? response.rows : response.rows.reverse();
            setDataAllBids(data);
            setTotal(response.count);
        }
        setLoading(false);
    };

    const resFiltersBits = (response: any) => {
        setLoading(false);
        if (response.length > 0) {
            if ([response[0].data.rows].length > 0) {
                setTotal(response[0].data.count);
                console.log(refDepartment)
                if (refDepartment) {
                    setDataAllBids(response[0].data.rows);
                    // dispatch(setRefDepartment(false))
                } else {
                    setDataAllBids((prev: any) => [...prev, ...response[0].data.rows]);
                    dispatch(setRefDepartment(false))
                }
                //  setCurrent(response[0].current);
                //  setPage(response[0].page);
                setHasMore(response[0].data.rows.length > 0);
            } else {
                message.error('Ошибка фильтрации!');
            }
        } else {
            message.error('Ошибка фильтрации!');
        }
    };

    const filtersColumn = (data: any, current: number, page: number, order: string) => {
        setLoading(true);
        if (storageLocal && storageLocal.pagination !== undefined) {
            storageLocal.pagination.current = current;
            storageLocal.pagination.page = page;
            if (storageLocal?.fiters) {
                data.push(storageLocal?.fiters[0]);
            }
        }
        setCurrent(current);
        if (Object.keys(data).length > 0) {
            dispatch(
                getFiltersBits({
                    data: data,
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        } else {
            dispatch(
                getAllBids({
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        }
    };

    const handleScroll = useCallback(() => {
        if (loading || !hasMore) return;
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 5) {
            setLoading(true);
            setPage((prev) => prev + 10);
            setCurrent((prev) => prev + 1);
            dispatch(setRefDepartment(false));
        }
    }, [loading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        if (storageLocal) {
            filtersColumn(storageLocal.fiters, current, page || 10, 'DESC');
        } else {
            filtersColumn([{department: ['Аренда']}], 1, 10, 'DESC')
        }
    }, [page, current]); //

    return (
        <div className={s.main__mobile}>
            <HeaderMobile/>
            <Flex className={s.containers} gap={10}>
                <CardList dataAllBids={dataAllBids}/>
                {loading &&
                    <Flex className={s.skeleton_box}>
                        <Skeleton active/>
                    </Flex>}
                {!hasMore && <div className={s.noMoreData}>Больше данных нет</div>}
            </Flex>
        </div>
    );
};

export default Mobile;