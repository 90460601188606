import React, {useEffect, useMemo, useState} from 'react';
import s from './page.module.scss'
import {IPagination, Table} from "../components/TableComponent/Table";
import {Utils} from "../utils";
import {IColumnType} from "../interface";
import {useDispatch, useSelector} from "react-redux";
import {filtersBits, getAllBids, getFiltersBits} from "../store/bids";
import socket from "../socket";
import {message} from "antd";
import {SessionStorageManager} from '../components/TableComponent/sessionStorageManager';
import {useLocation} from 'react-router-dom';
import {RootState} from "../store";
import {rentcolumns} from "./rentcolumns";
import {salecolumns} from "./salecolumns";
import {terminalscolumns} from "./terminalscolumns";
import Header from "../components/Header";

const Main = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [current, setCurrent] = useState<number>(1);
    const [page, setPage] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [dataAllBids, setDataAllBids] = useState<any>(Utils.defaultTable);
    const [columnFilters, setColumnFilters] = useState<any>({});
    const tableNum: number = useSelector((state: RootState) => state.bids.tableNum);
    const storageManager = new SessionStorageManager(location.pathname);
    const storageLocal: any = storageManager.getItem()

    useEffect(() => {
        if (storageLocal) {
            setCurrent(storageLocal.pagination.current);
            setColumnFilters(storageLocal.arrFilters);
            setCurrent(storageLocal.pagination.current);
            setPage(storageLocal.pagination.page);
            filtersColumn(storageLocal.fiters,
                storageLocal.pagination.current, storageLocal?.pagination?.page || 10, 'DESC')
        } else {
            filtersColumn([{department: ['Аренда']}], 1, 10, 'DESC')
        }
        socket.on('return-get-all-bids', resAllBids);
        socket.on('return-get-filters-bits', resFiltersBits);

        return () => {
            socket.off('return-get-all-bids', resAllBids);
            socket.off('return-get-filters-bits', resFiltersBits);
        };
    }, [])

    const columnsMemo = useMemo(() => {
        const labelFilter = sessionStorage.getItem('f' + location.pathname);
        if (labelFilter === 'Аренда') {
            return rentcolumns;
        } else if (labelFilter === 'Продажи') {
            return salecolumns;
        } else if (labelFilter === 'Терминалы') {
            return terminalscolumns;
        } else {
            return rentcolumns;
        }
    }, [tableNum]);

    const fetchData = (current: number, page: number, order: string) => {
        if (storageLocal?.fiters) {
            if (storageLocal.labelFilter) {
                filtersColumn([{department: [storageLocal.labelFilter]}],
                    storageLocal.pagination.current, storageLocal?.pagination?.page, 'DESC')
            } else {
                filtersColumn([{department: ['Аренда']}],
                    storageLocal.pagination.current, storageLocal?.pagination?.page, 'DESC')
            }
        } else {
            if (storageLocal.labelFilter) {
                filtersColumn([{department: [storageLocal.labelFilter]}], current, page, order)
            } else {
                filtersColumn([{department: ['Аренда']}], current, page, order)
            }
        }
    };

    const handleOrder = (order: string) => {
        setLoading(true);
        if (storageLocal) {
            filtersColumn(storageLocal.fiters,
                storageLocal.pagination.current, storageLocal?.pagination?.page || 10, order)
        } else {
            filtersColumn([{department: ['Аренда']}], 1, 10, order)
        }
    };

    const filtersColumn = (data: any, current: number, page: number, order: string) => {
        setLoading(true);
        if (storageLocal && storageLocal.pagination !== undefined) {
            storageLocal.pagination.current = current;
            storageLocal.pagination.page = page;
            if (storageLocal?.fiters) {
                data.push(storageLocal?.fiters[0]);
            }
        }
        setCurrent(current);
        if (Object.keys(data).length > 0) {
            dispatch(
                getFiltersBits({
                    data: data,
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        } else {
            dispatch(
                getAllBids({
                    current: current,
                    page: page,
                    is_archive: false,
                    order: order ? order : "DESC",
                })
            );
        }
    };

    const resAllBids = (response: any) => {
        if (response) {
            const data = response.order === "DESC" ? response.rows : response.rows.reverse();
            setDataAllBids(data);
            setTotal(response.count);
        }
        setLoading(false);
    };

    const filtersFetch = (text: string | number, column: IColumnType<any>) => {
        const labelFilter = sessionStorage.getItem('f' + location.pathname);
        dispatch(filtersBits({text: text, column: column, is_archive: false, department: labelFilter}));
    };

    const resFiltersBits = (response: any) => {
        setLoading(false);
        if (response.length > 0) {
            if ([response[0].data.rows].length > 0) {
                setTotal(response[0].data.count);
                setDataAllBids(response[0].data.rows);
                setCurrent(response[0].current);
                setPage(response[0].page);
            } else {
                message.error('Ошибка фильтрации!');
            }
        } else {
            message.error('Ошибка фильтрации!');
        }
    };

    const onChangePagination = async (page: IPagination<any>) => {
        setLoading(true);
        setCurrent(page.current);
        setPage(page.pageSize);
        try {
            if (storageLocal) {
                Object.assign(storageLocal, ({
                    pagination: {
                        page: page.pageSize,
                        current: page.current,
                        total: page.total
                    }
                }));
                storageManager.updateItem(storageLocal);
            }
            if (page.current != null && page.pageSize != null) {
                if (storageLocal) {
                    filtersColumn(storageLocal.fiters, page.current, page.pageSize || 10, 'DESC')
                } else {
                    fetchData(page.current, page.pageSize, "DESC");
                }
            }
        } catch (error) {
            console.error("Error changing pagination:", error);
        }
    };

    return (
        <div className={s.main}>
            <Header setColumnFilters={setColumnFilters}/>
            <Table
                order={handleOrder}
                setColumnFilters={setColumnFilters}
                columnFilters={columnFilters}
                height={'calc(-110px + 100vh)'}
                loading={loading}
                filters={filtersColumn}
                filtersFetch={filtersFetch}
                columns={columnsMemo}
                dataSource={dataAllBids}
                onChangePagination={onChangePagination}
                pagination={{pageSize: page, current: current, total: Number(total), showSizeChanger: true}}/>
        </div>
    );
};
export default Main;
