import React from 'react';
import { Flex, List } from "antd";
import s from "./filters.module.scss";
import Icon from "@ant-design/icons";
import { BackIcon } from "../../assets/images/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setCityFilter, setCityFilter1, setCountryFilter, setCountryFilter1 } from "../../store/bids";

const FiltersList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { val, dropdownText } = location.state || {};
    const countryData = useSelector((state: RootState) => state.bids.countryData);
    const countryData1 = useSelector((state: RootState) => state.bids.countryData1);
    const cityData = useSelector((state: RootState) => state.bids.cityData);
    const cityData1 = useSelector((state: RootState) => state.bids.cityData1);

    const dataMapping: Record<string, string[]> = {
        country: countryData,
        country1: countryData1,
        city: cityData,
        city1: cityData1,
    };

    const handleBack = () => {
        navigate('/filters', { state: { dropdownText } });
    };

    const handleItem = (item: string) => {
        const actionMap: Record<string, (item: string) => void> = {
            country: setCountryFilter,
            country1: setCountryFilter1,
            city: setCityFilter,
            city1: setCityFilter1,
        };

        const action: any = actionMap[val || ''];
        if (action) {
            dispatch(action(item || ''));
        }

        navigate('/filters', { state: { item, val, dropdownText } });
    };

    const dataSource = dataMapping[val || ''] || [];

    return (
        <>
            <Flex className={s.filters__header} align={"center"}>
                <Flex onClick={handleBack} align={"center"} style={{ padding: '0 10px' }}>
                    <Icon component={BackIcon} />
                    <div className={s.filters__header_back}>Назад</div>
                </Flex>
                <Flex align={"center"} style={{ position: 'absolute', left: 128, top: 10 }} justify={"center"}>
                    <div className={s.filters__title}>Выберите локацию</div>
                </Flex>
            </Flex>
            <Flex className={s.filters__container} vertical gap={12}>
                <List
                    dataSource={dataSource}
                    renderItem={(item: string) => (
                        <List.Item onClick={() => handleItem(item)}>
                            {item}
                        </List.Item>
                    )}
                />
            </Flex>
        </>
    );
};

export default FiltersList;
