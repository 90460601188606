import React from 'react';
import FiltersMobile from "../components/FiltersMobile";
import {useLocation} from "react-router-dom";

const Filters = () => {
    const location = useLocation();
    const { dropdownText } = location.state || {};

    return (
        <FiltersMobile dropdownText={dropdownText}/>
    );
};

export default Filters;